import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ILoginForm } from 'pages/Login/hook/useLoginForm.ts';
import { FormContentOption, Select, TextField } from '@zawarski/eti-typescript-components';
import { Grid } from '@mui/material';
import { scannerOptions } from '@/common/static';

type CreatePostViewProps = {
  activeIndex: number;
  lastIndexOfPost: number;
  form: UseFormReturn<ILoginForm>;
};

const CreatePostTwoView: React.FC<CreatePostViewProps> = ({
  activeIndex,
  lastIndexOfPost,
  form,
}) => {
  return (
    <>
      <FormContentOption title='POST OPTIONS' titleVariant={'h6'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS2.availablePosts.${lastIndexOfPost}.posts`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Posts'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS2.availablePosts.${lastIndexOfPost}.operator`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Operator'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS2.availablePosts.${lastIndexOfPost}.scribe`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Scribe'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS2.availablePosts.${lastIndexOfPost}.scannerModel`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Select
                  required
                  error={Boolean(error)}
                  helperText={error ? error.message : ''}
                  label='Scanner model'
                  selectOptions={scannerOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS2.availablePosts.${lastIndexOfPost}.scannerSerial`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Scanner serial'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormContentOption>
    </>
  );
};

export default CreatePostTwoView;
