// noinspection ExceptionCaughtLocallyJS

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchReceptionCenters } from '@/services/reception_center.endpoint.ts';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import {
  IReceptionCenter,
  IReceptionCenterEntity,
} from 'interfaces/reception_centers.interface.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
import { IStationSetup } from 'interfaces/admin.interface.ts';
import {
  fetchStationSetupByGroup,
  postStationSetup,
  putReceptionSettings,
} from '@/services/settings.endpoint.ts';
import { IReceptionSettingsPayload, ISettingsEntity } from 'interfaces/settings.interface.ts';
import type { RootState } from '@/store';

export const getReceptionCenters = createAsyncThunk(
  'ADMIN_PAGE/FETCH_RECEPTION_CENTERS',
  async (payload: Partial<IUserPortal>) => {
    try {
      const userAccountsInfo = payload;
      const activeCurrentGroup =
        userAccountsInfo?.currentGroups &&
        userAccountsInfo.currentGroups.length &&
        userAccountsInfo.currentGroups.find(
          (group) => group.i === userAccountsInfo.pvActiveGroupID
        );
      if (!activeCurrentGroup) throw Error('No active group found');
      const CountyName = activeCurrentGroup.n;
      const endpoint = fetchReceptionCenters({ county: CountyName });
      return await apiFetch<{ dataset: IReceptionCenter[] }>(endpoint).then(
        (res) => res.data ?? null
      );
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const saveStationSetup = createAsyncThunk(
  'ADMIN_PAGE/SAVE_STATION_SETUP',
  async (payload: IStationSetup) => {
    try {
      const endpoint = postStationSetup(payload);
      return await apiFetch<ISettingsEntity | null>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const getStationSetupByGroup = createAsyncThunk(
  'FETCH_STATION_SETUP_BY_GROUP',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { selectedGroup } = store.account;
      if (!selectedGroup) throw Error('No selected group found');
      const endpoint = fetchStationSetupByGroup(Number(selectedGroup));
      return await apiFetch<{
        count: number;
        dataset: ISettingsEntity[];
      } | null>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const saveReceptionSettings = createAsyncThunk(
  'SAVE_RECEPTION_SETTINGS',
  async (payload: IReceptionSettingsPayload) => {
    try {
      const endpoint = putReceptionSettings(payload);
      return await apiFetch<IReceptionCenterEntity>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
