import { createSlice } from '@reduxjs/toolkit';
import { RegisterInitialState, RegisterStateTypes } from './register.state.ts';
import { registerPost } from './register.thunk.ts';
import { QueryState } from '@/common/enum';

const initialState: RegisterStateTypes = RegisterInitialState;

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerPost.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(registerPost.fulfilled, (state) => {
        state.queryState = QueryState.CREATED;
      })
      .addCase(registerPost.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default registerSlice.reducer;
