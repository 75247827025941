import { StationInitialState, StationStateTypes } from './station2.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryState } from '@/common/enum';
import { updateStationTwo } from 'store/feature/station2/station2.thunk.ts';
import { IStationTwoAvailablePosts } from 'interfaces/admin.interface.ts';

const initialState: StationStateTypes = StationInitialState;

export const station2Slice = createSlice({
  name: 'station2',
  initialState,
  reducers: {
    updateSelectedPost: (state, action: PayloadAction<IStationTwoAvailablePosts>) => {
      state.selectedPostSetting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateStationTwo.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(updateStationTwo.fulfilled, (state) => {
        state.queryState = QueryState.UPDATED;
      })
      .addCase(updateStationTwo.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default station2Slice.reducer;
