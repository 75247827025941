import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ILoginForm } from 'pages/Login/hook/useLoginForm.ts';
import { FormContentOption, TextField } from '@zawarski/eti-typescript-components';
import { Grid } from '@mui/material';

type CreatePostViewProps = {
  activeIndex: number;
  lastIndexOfPost: number;
  isCombo: boolean;
  form: UseFormReturn<ILoginForm>;
};

const CreatePostThreeView: React.FC<CreatePostViewProps> = ({
  activeIndex,
  lastIndexOfPost,
  isCombo,
  form,
}) => {
  return (
    <>
      <FormContentOption title='POST OPTIONS'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS3.availablePosts.${lastIndexOfPost}.posts`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Posts'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          {!isCombo ? (
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name={`receptions.${activeIndex}.settingsS3.availablePosts.${lastIndexOfPost}.operator`}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    required
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ''}
                    label='Operator / Scribe'
                    value={value ?? ''}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Controller
                  control={form.control}
                  name={`receptions.${activeIndex}.settingsS3.availablePosts.${lastIndexOfPost}.operator`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      required
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message ?? ''}
                      label='Operator'
                      value={value ?? ''}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={form.control}
                  name={`receptions.${activeIndex}.settingsS3.availablePosts.${lastIndexOfPost}.scribe`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      required
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message ?? ''}
                      label='Scribe'
                      value={value ?? ''}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS3.availablePosts.${lastIndexOfPost}.scannerNo`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Scanner'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS3.availablePosts.${lastIndexOfPost}.probeSerial`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  required
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Probe serial'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormContentOption>
    </>
  );
};

export default CreatePostThreeView;
