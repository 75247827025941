import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect } from '@/common/enum';
import { RegisterPayload } from 'interfaces/register.interface.ts';

export const createGroup = (data: RegisterPayload): IEndPoint => ({
  authorization: AuthProtect.NOT_REQUIRED,
  method: 'POST',
  url: '/api/register',
  data,
});
