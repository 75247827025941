import * as AWS from 'aws-sdk';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoIdToken,
} from 'amazon-cognito-identity-js';
import { IidTokenSession, IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
import { getAccountFromIdToken } from '@/utils';

AWS.config.region = 'us-east-1';
const USER_POOL_ID = 'us-east-1_3f1efBpoo';
const USER_POOL_CLIENT_ID = '4i0bpro8bq9cusqkigt1j4gb9f';
const USER_POOL: CognitoUserPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: USER_POOL_CLIENT_ID,
  AdvancedSecurityDataCollectionFlag: true,
});

function SetNewCognitoUser(payload: {
  username: string;
  password: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL,
      });
      // !:NEED THIS TO TRIGGER USER MIGRATION
      currentUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password,
          // ClientMetadata: {
          //   ActiveGroupID: payload.activeId ? payload.activeId.toString() : '0',
          // },
        }),
        {
          onSuccess(_session) {
            // COGNITO_USER = currentUser;
            console.log('onSuccess currentUser >>>', currentUser);
            return res(currentUser);
          },
          onFailure(error) {
            //	* "code": "NotAuthorizedException"
            //	* "name": "NotAuthorizedException"
            //	* "message": "Incorrect username or password."
            //	* "code": "UserNotFoundException"
            //  * "name": "UserNotFoundException"
            console.log('onFailure error >>>', error);
            let message = 'Un-authorize User Account';
            const listPropertyNames = Object.keys(error);
            for (const key in listPropertyNames) {
              if (key === 'NotAuthorizedException') {
                message = 'Incorrect username or password.';
              }
            }
            return rej(message);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
}

function checkActiveSession(payload: {
  username: string;
  password: string;
  activeId?: string;
}): Promise<CognitoUser | null> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (res, rej) => {
    try {
      let currentUser = USER_POOL && USER_POOL.getCurrentUser();
      if (currentUser !== null) {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      } else {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

const CognitoLogin = async (payload: { username: string; password: string; activeId?: string }) => {
  try {
    console.log('Execute Cognito Login');
    const userCognitoAccount: CognitoUser | null = await checkActiveSession(payload)
      .then((session) => session)
      .catch((e) => {
        console.log('checkActiveSession error >>>', e);
        throw e;
      });

    if (userCognitoAccount) {
      let idToken: CognitoIdToken | undefined;
      if (userCognitoAccount?.getSignInUserSession()) {
        idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
      }

      if (!idToken) return Promise.reject('No Id Token'); //throw Error('No Id Token');
      const tokenSession: Partial<IidTokenSession> = idToken;
      const jwtToken = idToken.getJwtToken();
      let accountInfo: Partial<IUserPortal> = getAccountFromIdToken(idToken);
      accountInfo = JSON.parse(accountInfo as string);
      // console.log('PARSE accountInfo >>>', accountInfo);
      return {
        jwtToken,
        accountInfo,
        cognitoUser: JSON.stringify(tokenSession),
        activeGroupId: accountInfo.pvActiveGroupID,
      };
    }
    return Promise.reject('Cognito Login Error');
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('cognitoLogin catch:', error);
    throw Error(message);
  }
};

export default CognitoLogin;
