import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { ScannerModels } from '@/common/enum';

export const Stations = [
  { title: 'station 1', value: '1' },
  { title: 'station 2', value: '2' },
  { title: 'station 3', value: '3' },
  { title: 'station 4', value: '4' },
  { title: 'station 5', value: '5' },
];

export const StationTitle = [
  { title: 'Station 1', description: 'Vehicle Monitoring' },
  { title: 'Station 2', description: 'Evacuee Monitoring' },
  { title: 'Station 3', description: 'Decontamination and Post Decon' },
  { title: 'Station 4', description: 'Registration' },
  { title: 'Station 5', description: 'KI Distribution' },
];

export const withContamination = 299;

export const DECON_SELECTED_INCIDENT_SETTING_TYPE = 'deconSelectedIncident';

export const ADMIN_USERS_TEMP = [11399, 9847, 10383, 115, 4883, 907, 3344, 9006];

export const scannerOptions: RadioSelectOptions[] = [
  {
    value: ScannerModels.Model52,
    label: 'Model 52',
  },
  { value: ScannerModels.Model521, label: 'Model 52-1' },
];
