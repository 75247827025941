import { useForm } from 'react-hook-form';
import { Nullable } from 'interfaces/Nullable.ts';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { IReceptionExtended } from 'pages/Login/hook/useLoginForm.ts';

export interface IStationModalForm {
  station: string;
  isOpen: boolean;
  isAdmin: boolean;
  receptionCenterID: number;
  showStationMenu: boolean;
  showPostMenu: boolean;
  selectedPost: Nullable<string>;
  stationOptions: RadioSelectOptions[];
  postsOptions: RadioSelectOptions[];
  receptionCentersOptions: RadioSelectOptions[];
  receptions: IReceptionExtended[];
}

export const defaultValues: IStationModalForm = {
  station: '',
  isOpen: false,
  isAdmin: false,
  receptionCenterID: 0,
  selectedPost: null,
  showStationMenu: false,
  showPostMenu: false,
  stationOptions: [],
  postsOptions: [],
  receptionCentersOptions: [],
  receptions: [],
};

function useStationModalForm() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({ defaultValues });
}

export default useStationModalForm;
