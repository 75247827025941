import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect } from '@/common/enum';
import {
  filterUserSettingsParams,
  IReceptionSettingsPayload,
} from 'interfaces/settings.interface.ts';
import { SaveIncidentSettingsPayload } from 'interfaces/incidents.interface.ts';
import { IStationSetup } from 'interfaces/admin.interface.ts';

function setUserSettingsParams(filters: filterUserSettingsParams): filterUserSettingsParams {
  const params: filterUserSettingsParams = {};
  if (filters.pvAccountID) {
    params.pvAccountID = filters.pvAccountID;
  }
  if (filters.pvSettingType) {
    params.pvSettingType = filters.pvSettingType;
  }
  if (filters.pvGroupID) {
    params.pvGroupID = filters.pvGroupID;
  }
  return params;
}

export const fetchUserSettings = (filters: filterUserSettingsParams): IEndPoint => {
  const params = setUserSettingsParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/settings',
    params: params,
  };
};

export const postDeconIncident = (data: SaveIncidentSettingsPayload): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/api/incident',
    data,
  };
};

export const postStationSetup = (data: IStationSetup): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data.id ? 'PUT' : 'POST',
  url: '/api/station-setup',
  data,
});

export const fetchStationSetupByGroup = (groupID: number): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/station-setup',
  params: { groupID },
});

export const putReceptionSettings = (data: IReceptionSettingsPayload): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'put',
  url: '/api/reception-settings',
  data,
});

export const fetchStationSettingsGroupRC = (groupID: number, receptionID: number): IEndPoint => {
  const params = { groupID, receptionID };
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: '/api/county-registration',
    params,
  };
};
