import { createAsyncThunk } from '@reduxjs/toolkit';
import { GroupEntity, RegisterPayload } from 'interfaces/register.interface.ts';
import { createGroup } from '@/services/register.endpoint.ts';
import apiFetch from '@/services/apiFetch.ts';

export const registerPost = createAsyncThunk('REGISTER/POST', async (payload: RegisterPayload) => {
  try {
    const endpoint = createGroup(payload);
    return await apiFetch<GroupEntity>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    console.log(error);
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
