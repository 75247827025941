import moment from 'moment-timezone';
import { Nullable } from 'interfaces/Nullable.ts';
import type { Moment } from 'moment';

moment.tz.setDefault('America/New_York');

export const convertToTitleCase = (str: string) => {
  str = str.toLowerCase();
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export function PhoneNumberFormat(value: string | null | undefined) {
  if (!value) return value;
  const phoneNumber = value.toString().replace(/\D/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

type DateTimeFormatProps = {
  dte: string | null | undefined | Date | Moment;
  format?: string;
};
export const DateTimeFormat = ({ dte, format = 'MM-DD-YYYY HH:MM' }: DateTimeFormatProps) => {
  if (dte === undefined || dte === null) return '';
  return moment.utc(dte).format(format);
};

export const getAge = (dob: Nullable<string>) => {
  if (!dob) return 0;
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const convertValueToDOB = (str: string) => {
  if (!str) return '';
  const month = str.substring(0, 2);
  const day = str.substring(2, 4);
  const year = str.substring(4, 8);
  return `${year}-${month}-${day}`;
};
