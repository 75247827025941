import DefaultPaletteOptions from '../defaultPallete';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function MuiButtonStyle(pallet: DefaultPaletteOptions): any {
  return {
    styleOverrides: {
      root: {
        variant: 'contained',
        minHeight: 40,
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 600,
        color: '#FFF',
        textTransform: 'initial',
        '& .MuiButton-endIcon': {
          position: 'absolute',
          right: '20px',
        },
      },
      outlined: {
        border: 0,
        color: pallet.primary?.main,
      },
      outlinedSecondary: {
        borderColor: pallet.primary?.light,
        backgroundColor: '#FFF',
      },
      sizeSmall: {
        minHeight: 38,
        fontSize: 12,
      },
      sizeLarge: {
        minHeight: 46,
        fontSize: 16,
      },
    },
  };
}
