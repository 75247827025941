import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IncidentEntityType, UserIncidentsType } from 'interfaces/incidents.interface.ts';

export type IncidentsStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  userIncidents: Nullable<UserIncidentsType[]>;
  selectedIncident: Nullable<UserIncidentsType>;
  selectedIncidentSettingID: Nullable<number>;
  activeDeconIncident: Nullable<IncidentEntityType>;
};

export const IncidentsInitialState: IncidentsStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  userIncidents: null,
  selectedIncident: null,
  selectedIncidentSettingID: null,
  activeDeconIncident: null,
};
