import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import { fetchStationSettingsGroupRC } from '@/services/settings.endpoint.ts';
import {
  IStationCountyRCSettings,
  SelfRegisterLoaderResponse,
} from 'interfaces/settings.interface.ts';

const SelfRegisterPage = React.lazy(() => import('./SelfRegister.container.tsx'));

// eslint-disable-next-line react-refresh/only-export-components
export const loaderSelfRegister = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id, receptionId } = params;
    if (!id || !receptionId) {
      return defer({ data: null });
    }

    const endpoint = fetchStationSettingsGroupRC(Number(id), Number(receptionId));
    const response = apiFetch<IStationCountyRCSettings[] | null>(endpoint).then(
      (res) => res.data ?? null
    );
    return defer({
      data: response,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as IStationCountyRCSettings[] | null;
  return <SelfRegisterPage data={data} />;
}

const SelfRegisterLoaderContainer: React.FC = () => {
  const data = useLoaderData() as SelfRegisterLoaderResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={data.data}
        errorElement={
          <ErrorHandler headerTitle='Registration Page' errorMsg='Something went wrong' />
        }>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default SelfRegisterLoaderContainer;
