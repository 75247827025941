import { StationInitialState, StationStateTypes } from './station3.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateStationThree } from 'store/feature/station3/station3.thunk.ts';
import { QueryState } from '@/common/enum';
import { IStationThreeAvailablePosts } from 'interfaces/admin.interface.ts';

const initialState: StationStateTypes = StationInitialState;

export const station3Slice = createSlice({
  name: 'station3',
  initialState,
  reducers: {
    updateSelectedPost: (state, action: PayloadAction<IStationThreeAvailablePosts>) => {
      state.selectedPostSetting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateStationThree.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(updateStationThree.fulfilled, (state) => {
        state.queryState = QueryState.UPDATED;
      })
      .addCase(updateStationThree.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default station3Slice.reducer;
