import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PalmettoSideMenu from '@zawarski/palmetto-react-side-menu';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import StationIcon from '@mui/icons-material/TransferWithinAStation';
import { RoutePaths, ToastStatus, YesNo } from '@/common/enum';
import useStationModalForm, { IStationModalForm } from './hook/useStationModal.ts';
import { ModalOptions } from 'pages/Login/component';
import { ToastMessage } from 'components/primitive';
import { ILoginForm, IReceptionExtended } from 'pages/Login/hook/useLoginForm.ts';
import {
  IStationOneAvailablePosts,
  IStationThreeAvailablePosts,
  IStationTwoAvailablePosts,
} from 'interfaces/admin.interface.ts';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { IReceptionSettingsPayload } from 'interfaces/settings.interface.ts';
import { ADMIN_USERS_TEMP, Stations } from '@/common/static';
import { convertToTitleCase } from '@/utils';
import {
  accountSelector,
  selectedRCSelector,
  sideMenuPathSelector,
} from 'store/feature/accountInfo/account.selector.ts';
import {
  SET_ACTIVE_PATH,
  SET_RECEPTION_CENTER,
} from 'store/feature/accountInfo/account.actions.ts';
import {
  receptionCentersSelector,
  stationSettingsSelector,
} from 'store/feature/admin_page/admin.selector.ts';
import { updateStationOneSelectedPost } from 'store/feature/station1/station1.action.ts';
import { updateStationTwoSelectedPost } from 'store/feature/station2/station2.action.ts';
import { updateStationThreeSelectedPost } from 'store/feature/station3/station3.action.ts';
import { setStationNo } from 'store/feature/authToken/authToken.action.ts';
import { saveReceptionSettings } from 'store/feature/admin_page/admin.thunk.ts';

const appTitle = import.meta.env.VITE_APP_TITLE ?? 'Welcome to DECON';

const DrawerMenuView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountInfo = useAppSelector(accountSelector);
  const accountActivePath = useAppSelector(sideMenuPathSelector);
  const receptionCenters = useAppSelector(receptionCentersSelector);
  const stationSettings = useAppSelector(stationSettingsSelector);
  const selectedRC = useAppSelector(selectedRCSelector);
  const formModal: UseFormReturn<ILoginForm> = useStationModalForm();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const userFullName = useMemo(() => {
    return `${accountInfo?.pvPersonGivenName} ${accountInfo?.pvPersonSurName}`;
  }, [accountInfo]);

  const isUserAdmin = useMemo(
    () => ADMIN_USERS_TEMP.includes(Number(accountInfo?.id)),
    [accountInfo]
  );

  const defaultMenuItems = {
    menu: [
      {
        key: 'admin',
        name: 'Admin',
        path: RoutePaths.ADMIN,
        startIcon: <SettingsIcon />,
      },
      {
        key: 'station1',
        name: 'Station 1',
        path: RoutePaths.STATION1,
        startIcon: <StationIcon />,
      },
      {
        key: 'station2',
        name: 'Station 2',
        path: RoutePaths.STATION2,
        startIcon: <StationIcon />,
      },
      {
        key: 'station3',
        name: 'Station 3',
        path: RoutePaths.STATION3,
        startIcon: <StationIcon />,
      },
      {
        key: 'station4',
        name: 'Station 4',
        path: RoutePaths.STATION4,
        startIcon: <StationIcon />,
      },
      {
        key: 'station5',
        name: 'Station 5',
        path: RoutePaths.STATION5,
        startIcon: <StationIcon />,
      },
    ],
    section: [
      {
        key: 'user', // this is the unique key identifier
        name: userFullName, // this is the name that will be displayed in the menu
        path: RoutePaths.SETTINGS, // this is the path to the page
        startIcon: <PersonIcon />,
      },
      {
        key: 'logout',
        name: 'Log out',
        path: RoutePaths.LOGOUT,
        endIcon: <LogoutIcon sx={{ color: '#eb021d' }} />,
      },
    ],
    version: {
      name: 'Version 1.0.0', // Define the version number
      color: '#999', // Define the default color
    },
  };

  const stationOptions: RadioSelectOptions[] = useMemo(
    () => Stations.map((item) => ({ label: convertToTitleCase(item.title), value: item.value })),
    []
  );

  useEffect(() => {
    const handleToggle = () => setIsDrawerOpen((prevState) => !prevState);
    window.addEventListener('toggle-sidebar', handleToggle); // Listen for the event
    return () => window.removeEventListener('toggle-sidebar', handleToggle);
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };

  const handleMenuClick = (link: string) => {
    if (link === accountActivePath) return setIsDrawerOpen(false);
    if (link === RoutePaths.LOGOUT) return handleLogout();
    if (link === RoutePaths.SETTINGS) return null;
    if (link === RoutePaths.CONTACT) return null;

    dispatch(SET_ACTIVE_PATH(link as RoutePaths));
    if (link === RoutePaths.ADMIN) {
      setIsDrawerOpen(false);
      return setTimeout(() => navigate(link), 500);
    }

    if (link !== RoutePaths.ADMIN && stationSettings && receptionCenters.length > 0) {
      const receptions = stationSettings.reception
        .map((item) => {
          const S1AvailablePosts = JSON.parse(
            item.settingsS1.availablePosts
          ) as IStationOneAvailablePosts[];
          const S2AvailablePosts = JSON.parse(
            item.settingsS2.availablePosts
          ) as IStationTwoAvailablePosts[];
          const S3AvailablePosts = JSON.parse(
            item.settingsS3.availablePosts
          ) as IStationThreeAvailablePosts[];

          return {
            ...item,
            id: item.id ?? '',
            settingsS1: {
              ...item.settingsS1,
              id: item.settingsS1.id ?? 0,
              lastIndexOfPost: S1AvailablePosts.length,
              availablePosts: S1AvailablePosts,
            },
            settingsS2: {
              ...item.settingsS2,
              id: item.settingsS2.id ?? 0,
              lastIndexOfPost: S2AvailablePosts.length,
              availablePosts: S2AvailablePosts,
            },
            settingsS3: {
              ...item.settingsS3,
              id: item.settingsS3.id ?? 0,
              lastIndexOfPost: S3AvailablePosts.length,
              availablePosts: S3AvailablePosts,
            },
          };
        })
        .sort((a, b) => a.locationName.localeCompare(b.locationName));

      formModal.setValue('receptions', receptions);
      const rcOptions: RadioSelectOptions[] = stationSettings.reception
        .map((item) => ({
          value: item.pvDataID,
          label: item.locationName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      formModal.setValue('receptionCentersOptions', rcOptions);
      if (selectedRC) {
        const selectedRCOption = rcOptions.find((item) => item.value === selectedRC.pvDataID);
        if (selectedRCOption) {
          formModal.setValue('receptionCenterID', Number(selectedRCOption.value));
        }
      }
      const tempPostOptions: RadioSelectOptions[] = [{ label: 'Create new post', value: 'create' }];
      formModal.setValue('postsOptions', tempPostOptions);
      formModal.setValue('stationOptions', stationOptions);
      if (link === RoutePaths.STATION1) {
        formModal.setValue('station', '1');
      }
      if (link === RoutePaths.STATION2) {
        formModal.setValue('station', '2');
      }
      if (link === RoutePaths.STATION3) {
        formModal.setValue('station', '3');
      }
      if (link === RoutePaths.STATION4) {
        formModal.setValue('station', '4');
      }
      if (link === RoutePaths.STATION5) {
        formModal.setValue('station', '5');
      }
      return formModal.setValue('isOpen', true);
    }

    setIsDrawerOpen(false);
  };

  async function saveUpdatedReception(activeReception: IReceptionExtended) {
    //  *: Clean Up Posts Array
    const receptionCenter = activeReception;
    const settingS1AvailablePosts = activeReception.settingsS1.availablePosts
      .map((post, idx) => {
        if (Number(post.index) >= 0) return post;
        return {
          ...post,
          index: idx,
        };
      })
      .sort((a, b) => (a.index ?? Number.MAX_SAFE_INTEGER) - (b.index ?? Number.MAX_SAFE_INTEGER));
    const settingS2AvailablePosts = activeReception.settingsS2.availablePosts
      .map((post, idx) => {
        if (Number(post.index) >= 0) return post;
        return {
          ...post,
          index: idx,
        };
      })
      .sort((a, b) => (a.index ?? Number.MAX_SAFE_INTEGER) - (b.index ?? Number.MAX_SAFE_INTEGER));
    const settingS3AvailablePosts = activeReception.settingsS3.availablePosts
      .map((post, idx) => {
        if (Number(post.index) >= 0) return post;
        return {
          ...post,
          index: idx,
        };
      })
      .sort((a, b) => (a.index ?? Number.MAX_SAFE_INTEGER) - (b.index ?? Number.MAX_SAFE_INTEGER));

    receptionCenter.settingsS1.availablePosts = settingS1AvailablePosts.filter(
      (post) => post.posts && post.posts.length > 0
    );
    receptionCenter.settingsS2.availablePosts = settingS2AvailablePosts.filter(
      (post) => post.posts && post.posts.length > 0
    );
    receptionCenter.settingsS3.availablePosts = settingS3AvailablePosts.filter(
      (post) => post.posts && post.posts.length > 0
    );
    const receptionSettingsPayload: IReceptionSettingsPayload = {
      id: String(receptionCenter.id),
      settingsS1: {
        ...receptionCenter.settingsS1,
        availablePosts: JSON.stringify(receptionCenter.settingsS1.availablePosts),
      },
      settingsS2: {
        ...receptionCenter.settingsS2,
        availablePosts: JSON.stringify(receptionCenter.settingsS2.availablePosts),
      },
      settingsS3: {
        ...receptionCenter.settingsS3,
        availablePosts: JSON.stringify(receptionCenter.settingsS3.availablePosts),
      },
    };

    await dispatch(saveReceptionSettings(receptionSettingsPayload))
      .unwrap()
      .catch(() => {
        throw Error('Unable to save Reception Settings');
      });
  }

  const onConfirm: SubmitHandler<IStationModalForm> = async (data) => {
    if (processing) return;
    const activeReceptionIndex = data.receptions.findIndex(
      (reception) => reception.pvDataID === data.receptionCenterID
    );
    const activeReception =
      activeReceptionIndex > -1 ? data.receptions[activeReceptionIndex] : null;
    if (!activeReception) return;
    let hasError;
    const selectedStation = Number(data.station);
    const isCreatePost = data.selectedPost === 'create';
    let station1Post = {};
    let station2Post = {};
    let station3Post = {};

    if (activeReception) {
      if (selectedStation === 1 && isCreatePost) {
        const posts = activeReception.settingsS1.availablePosts;
        const lastPostsIndex = posts.length - 1;
        const createdPost: IStationOneAvailablePosts =
          activeReception.settingsS1.availablePosts[lastPostsIndex];
        station1Post = createdPost;
        for (const key in createdPost) {
          const createdPostKey = key as keyof IStationOneAvailablePosts;
          const value = createdPost[createdPostKey];
          if (!value) {
            hasError = true;
            //  *: Show error message
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const fieldName: any = `receptions.${activeReceptionIndex}.settingsS1.availablePosts.${lastPostsIndex}.${createdPostKey}`;
            formModal.setError(fieldName, { type: 'custom', message: 'Please enter a value.' });
          }
        }
      }
      if (selectedStation === 2 && isCreatePost) {
        const posts = activeReception.settingsS2.availablePosts;
        const lastPostsIndex = posts.length - 1;
        const createdPost: IStationTwoAvailablePosts =
          activeReception.settingsS2.availablePosts[lastPostsIndex];
        station2Post = createdPost;
        for (const key in createdPost) {
          const createdPostKey = key as keyof IStationTwoAvailablePosts;
          const value = createdPost[createdPostKey];
          if (!value) {
            hasError = true;
            //  *: Show error message
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const fieldName: any = `receptions.${activeReceptionIndex}.settingsS2.availablePosts.${lastPostsIndex}.${createdPostKey}`;
            formModal.setError(fieldName, { type: 'custom', message: 'Please enter a value.' });
          }
        }
      }
      if (selectedStation === 3 && isCreatePost) {
        const isCombo = activeReception.settingsS3.isOperatorScribeCombo === YesNo.Yes;
        const posts = activeReception.settingsS3.availablePosts;
        const lastPostsIndex = posts.length - 1;
        const createdPost = activeReception.settingsS3.availablePosts[lastPostsIndex];
        station3Post = createdPost;
        for (const key in createdPost) {
          const createdPostKey = key as keyof IStationThreeAvailablePosts;
          const value = createdPost[createdPostKey];
          if (!value) {
            if (isCombo) {
              hasError = true;
              //  *: Show error message
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const fieldName: any = `receptions.${activeReceptionIndex}.settingsS3.availablePosts.${lastPostsIndex}.${createdPostKey}`;
              formModal.setError(fieldName, { type: 'custom', message: 'Please enter a value.' });
            } else {
              if (createdPostKey === 'scribe') return;
              hasError = true;
              //  *: Show error message
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const fieldName: any = `receptions.${activeReceptionIndex}.settingsS3.availablePosts.${lastPostsIndex}.${createdPostKey}`;
              formModal.setError(fieldName, { type: 'custom', message: 'Please enter a value.' });
            }
          }
        }
      }
    }

    if (hasError) return;
    setProcessing(true);

    // *: SET ACTIVE RECEPTION CENTER
    dispatch(SET_RECEPTION_CENTER(activeReception));

    if (isCreatePost) {
      //  *: Save Updated Reception
      await saveUpdatedReception(activeReception).catch(() => {
        setProcessing(false);
        toast.info(<ToastMessage status={ToastStatus.ERROR} message='Unable to create new Post' />);
      });
      //  *: Set selected station settings STATE
      if (selectedStation === 1 && Object.keys(station1Post).length) {
        const selectedPost = station1Post as IStationOneAvailablePosts;
        dispatch(updateStationOneSelectedPost(selectedPost));
      }
      if (selectedStation === 2 && Object.keys(station2Post).length) {
        const selectedPost = station2Post as IStationTwoAvailablePosts;
        dispatch(updateStationTwoSelectedPost(selectedPost));
      }
      if (selectedStation === 3 && Object.keys(station3Post).length) {
        const selectedPost = station3Post as IStationThreeAvailablePosts;
        dispatch(updateStationThreeSelectedPost(selectedPost));
      }
    } else {
      //  *: Set selected station settings STATE
      if (selectedStation === 1) {
        const station1Post = activeReception.settingsS1.availablePosts.find(
          (post) => Number(post.index) === Number(data.selectedPost)
        );
        if (station1Post) dispatch(updateStationOneSelectedPost(station1Post));
      }
      if (selectedStation === 2) {
        const station2Post = activeReception.settingsS2.availablePosts.find(
          (post) => Number(post.index) === Number(data.selectedPost)
        );
        if (station2Post) dispatch(updateStationTwoSelectedPost(station2Post));
      }
      if (selectedStation === 3) {
        const station3Post = activeReception.settingsS3.availablePosts.find(
          (post) => Number(post.index) === Number(data.selectedPost)
        );
        if (station3Post) dispatch(updateStationThreeSelectedPost(station3Post));
      }
    }
    setProcessing(false);
    dispatch(setStationNo(selectedStation));
    formModal.setValue('isOpen', false);
    setIsDrawerOpen(false);
    setTimeout(() => navigate(accountActivePath), 500);
  };

  return (
    <React.Fragment>
      <PalmettoSideMenu
        srcLogo={'/images/launcher.png'}
        menuItems={
          isUserAdmin
            ? defaultMenuItems
            : {
                ...defaultMenuItems,
                menu: defaultMenuItems.menu.filter((link) => link.key !== 'admin'),
              }
        }
        isDrawerOpen={isDrawerOpen}
        activePath={accountActivePath}
        activeSubMenu={false}
        programTitle={appTitle}
        onDrawerClose={() => setIsDrawerOpen(false)}
        onMenuClick={handleMenuClick}
        deactivateSubMenu={() => null}
      />
      <ModalOptions
        form={formModal}
        onConfirm={formModal.handleSubmit(onConfirm)}
        goToAdmin={() => null}
        processing={processing}
      />
    </React.Fragment>
  );
};

export default DrawerMenuView;
