import React, { useMemo } from 'react';
import { useAsyncError } from 'react-router-dom';
import Box, { BoxProps } from '@mui/material/Box';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderComponent } from '@zawarski/eti-typescript-components';
import { ErrorMessage } from 'components/primitive';

const toggleDrawer = () => window.dispatchEvent(new CustomEvent('toggle-sidebar'));

type ErrorHandlerViewProps = {
  headerTitle: string;
  errorMsg?: string;
};
const ErrorHandlerView: React.FC<ErrorHandlerViewProps & BoxProps> = ({
  headerTitle,
  errorMsg,
  ...props
}) => {
  const asyncError = useAsyncError();

  const errorAsyncMsg = useMemo(() => {
    if (asyncError && asyncError instanceof Error) {
      const msg = JSON.parse(JSON.stringify(asyncError.message));
      if (typeof msg === 'string') return msg;
      if (typeof msg === 'object' && msg.message) return msg.message;
      return msg;
    }
    return errorMsg;
  }, [asyncError, errorMsg]);
  return (
    <Box className='layout vertical full-height'>
      <HeaderComponent
        title={headerTitle}
        icon={
          <IconButton onClick={() => toggleDrawer()}>
            <MenuIcon className='icon' />
          </IconButton>
        }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
        {errorAsyncMsg && <ErrorMessage message={errorAsyncMsg} />}
        {props.children}
      </Box>
    </Box>
  );
};

export default ErrorHandlerView;
