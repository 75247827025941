import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles from './Loading.styles.ts';

type LoadingProps = {
  message?: string;
  size?: string | number;
  thickness?: number;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
};

const Loading: React.FC<LoadingProps> = ({
  message = 'Loading, please wait...',
  color = 'primary',
  size = '3rem',
  thickness = 4,
}) => {
  const classes = useStyles();
  return (
    <Box sx={classes.container}>
      <CircularProgress size={size} color={color} thickness={thickness} />
      <Box sx={classes.wrapper}>{message}</Box>
    </Box>
  );
};

export default Loading;
