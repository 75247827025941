import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import { AuthProtect, BaseURLSelection } from '@/common/enum';
import type EndPointTypes from 'interfaces/EndPointTypes.ts';
import { store } from '@/store';

export const genericErrorMessage =
  'We just experienced a technical issue, please try again or contact support.';

const DeconBaseURL = import.meta.env.VITE_DECON_API_ENDPOINT;
const palmettoBaseURL = import.meta.env.VITE_MAIN_PALMETTO_ENDPOINT;

async function apiFetch<T>(
  api: EndPointTypes,
  controller?: AbortController
): Promise<{ status: number; data: T }> {
  const params: any = api.params || {}; // eslint-disable-line
  const { headers } = apiConfig;
  const baseURL = api.defaultBaseURL === BaseURLSelection.PALMETTO ? palmettoBaseURL : DeconBaseURL;
  if (api.authorization === AuthProtect.REQUIRED) {
    const jwt = store.getState().authToken.token;
    if (jwt) {
      params.access_token = jwt;
    }
  }
  const apiConfigMerge: AxiosRequestConfig = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params,
    },
  ]);
  const axiosCreate = axios.create(apiConfigMerge);
  axiosCreate.interceptors.request.use(
    async function (config) {
      if (controller) {
        config.signal = controller.signal;
      }
      if (api.authorization === AuthProtect.REQUIRED) {
        const jwt = store.getState().authToken.token;
        if (jwt) {
          config.headers.Authorization = jwt;
        }
      }
      return config;
    },
    function (error) {
      console.log('apiFetch function ERROR: ', error);
      throw Error(error);
    }
  );
  return axiosCreate(api).then(
    (res: AxiosResponse<T>) => {
      return { status: res.status, data: res.data };
    },
    (error) => {
      console.log('apiFetch ERROR: ', error);
      if (error.response) {
        throw Error(JSON.stringify(error.response.data));
        // return { status: error.response.status, data: error.response.data };
      } else {
        throw Error(genericErrorMessage);
        // return { status: 500, data: genericErrorMessage };
      }
    }
  );
}

export default apiFetch;
