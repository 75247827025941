import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import {
  selectAuthTokenError,
  selectAuthTokenQueryState,
  selectAuthTokenVerification,
} from 'store/feature/authToken/authToken.selector.ts';
import { QueryState } from '@/common/enum';
import { verifyToken } from 'store/feature/authToken/authToken.thunk.ts';
import { setAppLoading } from 'store/feature/authToken/authToken.action.ts';
import { ErrorMessage, Loading } from 'components/primitive';

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idToken = searchParams.get('id_token');
  const urlPath = location.pathname;
  const dispatch = useAppDispatch();
  const authTokenVerification = useAppSelector(selectAuthTokenVerification);
  const authTokenError = useAppSelector(selectAuthTokenError);
  const isLoading = useAppSelector(selectAuthTokenQueryState) === QueryState.AWAIT;
  const isError = useAppSelector(selectAuthTokenQueryState) === QueryState.FAIL;
  const isReady = useAppSelector(selectAuthTokenQueryState) === QueryState.READY;
  useEffect(() => {
    if (idToken) {
      dispatch(verifyToken(idToken));
      dispatch(setAppLoading(true));
    }
  }, [dispatch, idToken]);

  useEffect(() => {
    // if (urlPath === '/admin') return navigate('/admin');
    if (!authTokenVerification) {
      if (urlPath === '/') return navigate('/login');
      if (urlPath.includes('station')) return navigate('/login');
      if (urlPath.includes('admin')) return navigate('/login');
    }
  }, [authTokenVerification, isReady, navigate, urlPath]);

  if (isLoading) {
    return <Loading message={'Authentication in process, please wait...'} />;
  }

  if (isError) {
    return <ErrorMessage message={authTokenError ?? 'Failed to validate user token.'} />;
  }

  return <Outlet />;
};

export default AuthPage;
