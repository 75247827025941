import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IncidentsInitialState, IncidentsStateTypes } from './incidents.state.ts';
import {
  fetchActiveIncidentByGroup,
  fetchUserSavedIncidents,
} from 'store/feature/incidents/incidents.thunk.ts';
import { QueryState } from '@/common/enum';
import { genericErrorMessage } from '@/services/apiFetch.ts';
import { IncidentEntityType, UserIncidentsType } from 'interfaces/incidents.interface.ts';

const initialState: IncidentsStateTypes = IncidentsInitialState;

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    updateDeconIncident: (state, action: PayloadAction<IncidentEntityType>) => {
      state.activeDeconIncident = action.payload;
    },
    updateStateStatus: (state, action: PayloadAction<QueryState>) => {
      state.queryState = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserSavedIncidents.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchUserSavedIncidents.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.userIncidents = null;
        state.error = action.error.message ?? genericErrorMessage;
      })
      .addCase(fetchUserSavedIncidents.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.userIncidents = action.payload.dataset ?? [];
      })
      .addCase(fetchActiveIncidentByGroup.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        const settings = action.payload;
        state.selectedIncidentSettingID = settings?.pvSettingID ?? null;
        if (settings && settings.pvSettingValue && settings.pvSettingValue.length) {
          state.selectedIncident = JSON.parse(settings.pvSettingValue) as UserIncidentsType;
        }
      })
      .addCase(fetchActiveIncidentByGroup.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? genericErrorMessage;
      });
  },
});

export default incidentsSlice.reducer;
