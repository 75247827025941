import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardInitialState, DashboardStateTypes } from './dashboard.state';
import { IGroupListData, IMemberListData } from 'interfaces/StationForms.ts';
import { IQueueMemberDataResp } from 'interfaces/station.interface.ts';

const initialState: DashboardStateTypes = DashboardInitialState;

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setActiveGroupId: (state, action: PayloadAction<number | null>) => {
      state.activeGroupId = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<IGroupListData | null>) => {
      state.selectedGroup = action.payload;
    },
    setActiveMemberId: (state, action: PayloadAction<number | null>) => {
      state.activeMemberId = action.payload;
    },
    setSelectedMember: (state, action: PayloadAction<IMemberListData | null>) => {
      state.selectedMember = action.payload;
    },
    setMembersByGroup: (state, action: PayloadAction<IQueueMemberDataResp[]>) => {
      state.membersByGroup = action.payload;
    },
  },
});

export const {
  setActiveGroupId,
  setSelectedGroup,
  setActiveMemberId,
  setSelectedMember,
  setMembersByGroup,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
