import { Nullable } from 'interfaces/Nullable.ts';
import { IGroupListData, IMemberListData } from 'interfaces/StationForms.ts';
import { IQueueMemberDataResp } from 'interfaces/station.interface.ts';

export type DashboardStateTypes = {
  activeGroupId: Nullable<number>;
  selectedGroup: Nullable<IGroupListData>;
  activeMemberId: Nullable<number>;
  selectedMember: Nullable<IMemberListData>;
  membersByGroup: IQueueMemberDataResp[];
};

export const DashboardInitialState: DashboardStateTypes = {
  activeGroupId: null,
  selectedGroup: null,
  activeMemberId: null,
  selectedMember: null,
  membersByGroup: [],
};
