import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import type { RootState } from '@/store';
import { fetchUserSettings, postDeconIncident } from '@/services/settings.endpoint.ts';
import { ISettings } from 'interfaces/settings.interface.ts';
import { SaveIncidentSettingsPayload, UserIncidentsType } from 'interfaces/incidents.interface.ts';
import { DECON_SELECTED_INCIDENT_SETTING_TYPE } from '@/common/static';

export const saveDeconIncident = createAsyncThunk(
  'INCIDENTS/SAVE_DECON_INCIDENT',
  async (incident: UserIncidentsType, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { selectedGroup } = store.account;
      const { selectedIncidentSettingID } = store.incidents;
      if (!selectedGroup) return Promise.reject('Group not found');
      const groupID = Number(selectedGroup);
      const payload: SaveIncidentSettingsPayload = {
        pvSettingId: selectedIncidentSettingID ? selectedIncidentSettingID : 0,
        pvSettingType: DECON_SELECTED_INCIDENT_SETTING_TYPE,
        pvGroupID: groupID,
        pvSettingValue: JSON.stringify(incident),
      };
      const endpoint = postDeconIncident(payload);
      return await apiFetch<ISettings>(endpoint).then((resp) => resp.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const fetchUserSavedIncidents = createAsyncThunk(
  'INCIDENTS/FETCH_USER_SAVED_INCIDENTS',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { account, selectedGroup } = store.account;
      if (!account || !account.id || !selectedGroup) return Promise.reject('Account not found');
      const accountID = account.id;
      const f = {
        pvAccountID: accountID,
        pvSettingType: 'selectedIncidents',
        pvGroupID: Number(selectedGroup),
      };
      const endpoint = fetchUserSettings(f);
      return await apiFetch<{ dataset: UserIncidentsType[] }>(endpoint).then(
        (resp) => resp.data ?? { dataset: [] }
      );
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const fetchActiveIncidentByGroup = createAsyncThunk(
  'INCIDENTS/FETCH_ACTIVE_INCIDENT_BY_GROUP',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { account, selectedGroup } = store.account;
      if (!account || !account.id || !selectedGroup)
        return Promise.reject('Account or Group not found');
      // *: Get the active incident
      const f = {
        pvGroupID: Number(selectedGroup),
        pvSettingType: DECON_SELECTED_INCIDENT_SETTING_TYPE,
      };
      const endpoint = fetchUserSettings(f);
      const userSettings = await apiFetch<{ dataset: ISettings[] }>(endpoint).then(
        (resp) => resp.data ?? { dataset: [] }
      );
      if (!userSettings || !userSettings.dataset || !userSettings.dataset.length) return null;
      return userSettings.dataset[0];
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
