import { StationInitialState, StationStateTypes } from './station4.state';
import { createSlice } from '@reduxjs/toolkit';
import { updateStationFour } from 'store/feature/station4/station4.thunk.ts';
import { QueryState } from '@/common/enum';

const initialState: StationStateTypes = StationInitialState;

export const station4Slice = createSlice({
  name: 'station4',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateStationFour.pending, (state) => {
      state.queryState = QueryState.AWAIT;
      state.error = null;
    });
    builder.addCase(updateStationFour.fulfilled, (state) => {
      state.queryState = QueryState.UPDATED;
    });
    builder.addCase(updateStationFour.rejected, (state, action) => {
      state.queryState = QueryState.AWAIT;
      state.error = action.error.message ?? '';
    });
  },
});

export default station4Slice.reducer;
