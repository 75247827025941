import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '8px',
    maxHeight: '650px',
    minWidth: '600px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    borderRadius: '8px',
    bgcolor: '#F0F0F0',
    border: '1px solid #E0E0E0',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    alignItems: 'center',
    borderRadius: '8px',
    gap: '16px',
    padding: '24px',
    bgcolor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
});

export default useStyles;
