import authTokenReducer from './authToken/authToken.slice.ts';
import registerReducer from './register/register.slice.ts';
import station1Reducer from './station1/station1.slice.ts';
import station2Reducer from './station2/station2.slice.ts';
import station3Reducer from './station3/station3.slice.ts';
import station4Reducer from './station4/station4.slice.ts';
import station5Reducer from './station5/station5.slice.ts';
import dashboardReducer from './dashboard/dashboard.slice.ts';
import adminPageReducer from './admin_page/admin.slice.ts';
import accountReducer from './accountInfo/account.slice.ts';
import incidentsReducer from './incidents/incidents.slice.ts';

export const rootReducers = {
  account: accountReducer,
  authToken: authTokenReducer,
  adminPage: adminPageReducer,
  dashboard: dashboardReducer,
  incidents: incidentsReducer,
  register: registerReducer,
  station1: station1Reducer,
  station2: station2Reducer,
  station3: station3Reducer,
  station4: station4Reducer,
  station5: station5Reducer,
};
