import TextField, { TextFieldProps as MTextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/system';

interface ExtendedTextFieldProps {
  minWidth?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
}

const NormalTextField = styled((props: ExtendedTextFieldProps & MTextFieldProps) => (
  <TextField
    sx={{
      input: { cursor: props?.InputProps?.readOnly ? 'default' : 'text' },
    }}
    {...props}
  />
))(
  ({
    theme,
    minWidth = '120px',
    marginLeft = '6px',
    marginRight = '6px',
    marginTop = 'auto',
    marginBottom = 'auto',
    error,
    ...props
  }) => ({
    minWidth,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    minHeight: '56px',
    '& label': {
      position: 'absolute',
      transform: 'translate(0, -1.5px) scale(0.75)',
      transformOrigin: 'top left',
    },
    '& > label': {
      lineHeight: 1.33,
      caretColor: 'transparent',
      color: `${theme.palette.text?.secondary} `,
    },
    ...(props?.InputProps?.readOnly && {
      '& > div:before': {
        borderBottom: `1px solid ${
          error ? theme.palette.error.main : theme.palette.text?.secondary
        } !important`,
      },
      '& > div:after': {
        borderBottom: `1px solid ${
          error ? theme.palette.error.main : theme.palette.text?.secondary
        } !important`,
      },
      '& > div:hover:after': {
        borderBottom: `1px solid ${
          error ? theme.palette.error.main : theme.palette.text?.secondary
        } !important`,
      },
    }),
    '& > div:hover:before': {
      borderBottom: `1px solid ${
        error ? theme.palette.error.main : theme.palette.text.secondary
      } !important`,
    },
  })
);

export default NormalTextField;
