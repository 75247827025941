import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ILoginForm } from 'pages/Login/hook/useLoginForm.ts';
import {
  FormContentOption,
  FormGroup,
  RadioButtonsGroup,
  TextField,
} from '@zawarski/eti-typescript-components';
import { Grid } from '@mui/material';
import { YesNo } from '@/common/enum';

type CreatePostViewProps = {
  activeIndex: number;
  lastIndexOfPost: number;
  form: UseFormReturn<ILoginForm>;
};

const CreatePostOneView: React.FC<CreatePostViewProps> = ({
  activeIndex,
  lastIndexOfPost,
  form,
}) => {
  return (
    <>
      <FormContentOption title='CONFIGURATION'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS1.requireInterior`}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormGroup
                  label='Is the interior scanning required?'
                  error={Boolean(error)}
                  helperText={error?.message}
                  sx={{ maxWidth: '500px' }}>
                  <RadioButtonsGroup
                    row
                    radioOptions={[
                      { label: 'Yes', value: YesNo.Yes },
                      { label: 'No', value: YesNo.No },
                    ]}
                    value={String(value)}
                    onChange={onChange}
                  />
                </FormGroup>
              )}
            />
          </Grid>
        </Grid>
      </FormContentOption>
      <FormContentOption title='POST OPTIONS'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.posts`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Posts'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.operator`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Operator'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.scribe`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Scribe'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.scannerNo`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Scanner number'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.probeSerial`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                  label='Probe serial'
                  value={value ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormContentOption>
    </>
  );
};

export default CreatePostOneView;
