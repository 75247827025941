import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect } from '@/common/enum';
import { filterReceptionCenter } from 'interfaces/reception_centers.interface.ts';

function setReceptionCenterParams(filters: filterReceptionCenter): filterReceptionCenter {
  const params: filterReceptionCenter = {};
  if (filters.county) {
    params.county = filters.county;
  }
  return params;
}

export const fetchReceptionCenters = (filter: filterReceptionCenter): IEndPoint => {
  const params = setReceptionCenterParams(filter);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/reception-centers',
    params: params,
  };
};
