enum RoutePaths {
  ADMIN = '/admin',
  STATION1 = '/station1',
  STATION2 = '/station2',
  STATION3 = '/station3',
  STATION4 = '/station4',
  STATION5 = '/station5',
  SETTINGS = '/settings',
  CONTACT = '/contact',
  LOGOUT = '/logout',
}

export default RoutePaths;
